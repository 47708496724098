import { useCallback, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import useAuth from "../../hooks/useAuth";
import HttpService from "../../services/http";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import Plans, { decideGeneralName, PlanNames } from "../../components/Plans";
import { toast } from "react-toastify";
import { Timer } from "./ForgotPassword";
import { Helmet } from "react-helmet-async";
import useAppLocalStorage from "../../hooks/useAppLocalStorage";
import { useTranslation } from "react-i18next";
import { Alert, Button, Modal } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";
import { HiEye, HiEyeOff } from "react-icons/hi";

const Login = () => {
  const localStorage = useAppLocalStorage();
  const [t] = useTranslation();

  const navigate = useNavigate();
  let location: any = useLocation();
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const queryClient = useQueryClient();

  const schema = Joi.object({
    user_email: Joi.string().trim().required().label(t("login.label_1")),
    user_password: Joi.string().trim().required().label(t("login.label_2")),
  }).required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: joiResolver(schema),
  });

  let from = location.state?.from?.pathname || "/control-panel";

  const [showTimer, setShowTimer] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [setUserEntityId, setSetUserEntityId] = useState<string>();
  const [isEmailVerificationError, setIsEmailVerificationError] =
    useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const LoginUserMutation = useMutation<any, AxiosError<any, any>>(
    (credentials: any) => HttpService.post("/auth/login", credentials),
    {
      onMutate: () => {
        setShowAlert(false);
        setIsEmailVerificationError(false);
      },
      onError: (err) => {
        localStorage.removeItem("_umd_credential");

        toast.error(err.response?.data?.message || err.message);

        if (
          err.response?.status === 400 &&
          err.response?.data.code === "RSUSKO014"
        ) {
          setUserId(err.response?.data.element.userIdentification);
          setIsEmailVerificationError(true);
          setShowAlert(true);
        }
      },
    }
  );

  const ResendEmailVerification = useMutation<
    any,
    AxiosError<any, any>,
    string
  >(
    (user_identification) =>
      HttpService.post(`/auth/${user_identification}/validation-link`),
    {
      onSuccess: () => {
        toast.success(t("login.msg"));

        setShowTimer(true);
      },
      onError: (err) => {
        toast.error(err.response?.data?.message || err.message);

        setShowTimer(false);
      },
    }
  );

  const autoLoginUser = useCallback(
    (parsedCredential: any, user_selected_plan: string) => {
      LoginUserMutation.mutate(parsedCredential, {
        onSuccess: (res) => {
          queryClient.invalidateQueries("payment-status");

          queryClient
            .fetchQuery("payment-status", () =>
              HttpService.get(
                `/auth/${res.data.element.userIdentification}/${res.data.element.entityIdentification}/verify-payment`
              )
            )
            .then((value) => {
              if (value?.data?.element?.is_new_user) {
                setUserId(res.data.element.userIdentification);
                setSetUserEntityId(res.data.element.entityIdentification);
                setShowPlansModal(true);
                setUserId(res.data.element.userIdentification);
                return;
              } else if (!value?.data?.element?.customer_plans?.length) {
                auth.onOffUserEndedSubscription(true);
              } else {
                auth.onOffUserEndedSubscription(false);
              }

              localStorage.removeItem("_umd_credential");
              localStorage.removeItem("_umd_service_client_plan");

              auth.login({ ...res.data.element, rememberMe });

              return navigate(
                `/control-panel?plan=${decideGeneralName(
                  user_selected_plan as PlanNames
                )}&plan_name=${user_selected_plan}&status=true`,
                { replace: true }
              );
            })
            .catch((err: any) => {
              localStorage.removeItem("_umd_credential");

              if (
                err.response?.data &&
                err.response?.data?.message !== "Payment checking"
              )
                toast.error(err.response?.data?.message);
              else if (!err.response) toast.error(err.message);

              if (err.response?.status === 402) {
                setShowPlansModal(true);
                setUserId(res.data.element.userIdentification);
              }
            });
        },
      });
    },
    // eslint-disable-next-line
    [auth, navigate, rememberMe, localStorage]
  );

  useEffect(() => {
    let cleaner = true;

    if (cleaner && auth.user.isAuthenticated)
      navigate(from + window.location.search);

    let credentials = localStorage.getItem("_umd_credential");
    let user_selected_plan = localStorage.getItem("_umd_service_client_plan");

    if (
      cleaner &&
      credentials &&
      user_selected_plan &&
      searchParams.has("status") &&
      searchParams.get("status") === "true"
    ) {
      let { is_new_user, user_email, user_password } = JSON.parse(
        credentials as string
      );

      if (is_new_user)
        autoLoginUser({ user_email, user_password }, user_selected_plan);
    } else if (
      cleaner &&
      credentials &&
      user_selected_plan &&
      searchParams.has("status") &&
      searchParams.get("status") === "false"
    ) {
      localStorage.removeItem("_umd_credential");
    }

    return () => {
      cleaner = false;
    };
  }, [auth, from, navigate, reset, autoLoginUser, searchParams, localStorage]);

  const handleResendValidationEmail = () => {
    if (userId) ResendEmailVerification.mutate(userId);
  };

  const onSubmit = (data: any) => {
    LoginUserMutation.mutate(data, {
      onSuccess: (res) => {
        queryClient.invalidateQueries("payment-status");

        queryClient
          .fetchQuery("payment-status", () =>
            HttpService.get(
              `/auth/${res.data.element.userIdentification}/${res.data.element.entityIdentification}/verify-payment`
            )
          )
          .then((value) => {
            if (value?.data?.element?.is_new_user) {
              localStorage.setItem(
                "_umd_credential",
                JSON.stringify({
                  user_email: data.user_email,
                  user_password: data.user_password,
                  rememberMe: rememberMe,
                  is_new_user:
                    res.data.element.entityStatus === "NEW" ? true : false,
                })
              );

              setShowPlansModal(true);
              setUserId(res.data.element.userIdentification);
              setSetUserEntityId(res.data.element.entityIdentification);
              return;
            } else if (!value?.data?.element?.customer_plans?.length) {
              auth.onOffUserEndedSubscription(true);
            } else {
              auth.onOffUserEndedSubscription(false);
            }

            auth.login({
              ...res.data.element,
              is_trial: value.data.element.is_trial,
              trial_left_days: value.data.element.trial_left_days,
              rememberMe,
            });

            if (
              localStorage.getItem("_umd_service_client_plan") &&
              searchParams.has("status") &&
              searchParams.get("status") === "true"
            ) {
              let user_selected_plan = localStorage.getItem(
                "_umd_service_client_plan"
              );

              localStorage.removeItem("_umd_service_client_plan");

              navigate(
                `/control-panel?plan=${decideGeneralName(
                  user_selected_plan as PlanNames
                )}&plan_name=${user_selected_plan}&status=true`,
                {
                  replace: true,
                }
              );
            } else navigate(from, { replace: true });
          })
          .catch((err: any) => {
            if (
              err.response?.data &&
              err.response?.data?.message !== "Payment checking"
            )
              toast.error(err.response?.data?.message);
            else if (!err.response) toast.error(err.message);

            if (err.response?.status === 402) {
              localStorage.setItem(
                "_umd_credential",
                JSON.stringify({
                  user_email: data.user_email,
                  user_password: data.user_password,
                  rememberMe: rememberMe,
                  is_new_user:
                    res.data.element.entityStatus === "NEW" ? true : false,
                })
              );

              setShowPlansModal(true);
              setUserId(res.data.element.userIdentification);
              setSetUserEntityId(res.data.element.entityIdentification);
            }
          });
      },
    });
  };

  return (
    <div className="login-page">
      <Helmet>
        <title>Sign in | Super User Manager</title>
        <meta
          name="description"
          content="Super User Manager - Login to dashboard"
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_HOST_URL}/login`}
        />
      </Helmet>

      <div className="md:w-[448px] w-full flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch flex-col justify-start items-center gap-6 flex">
          <div className="flex-col justify-start items-center gap-2 flex">
            <div className="text-center text-gray-700 text-3xl font-bold font-['Figtree'] leading-9">
              {t("login.title")}
            </div>
            <div className="justify-start items-center gap-1 inline-flex">
              <div className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight">
                {t("login.registerNote")}
              </div>
              <div className="justify-start items-center flex">
                <Link
                  to={"/register"}
                  className="text-sky-600 text-sm font-medium font-['Figtree'] leading-tight"
                >
                  {t("login.registerBtn")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        {searchParams.has("payment-status") &&
          searchParams.get("payment-status") === "failure" && (
            <Alert color="failure" className="w-full">
              <span className="font-medium">{t("login.alert_1")}</span>
            </Alert>
          )}

        {searchParams.has("status") &&
          searchParams.get("status") === "true" && (
            <Alert color="success" className="w-full">
              <span className="font-medium">{t("login.alert_2")}</span>
            </Alert>
          )}

        {isEmailVerificationError && showAlert && (
          <Alert
            color="warning"
            className="w-full"
            onDismiss={() => setShowAlert(false)}
          >
            {t("login.alert_3")}
            {showTimer ? (
              <span className="text-muted ml-2">
                <Timer setShowTimer={setShowTimer} ttl={120} />{" "}
                {t("login.alert_4")}
              </span>
            ) : (
              <button
                className="ml-2 underline"
                style={{ verticalAlign: "top", outline: "none" }}
                onClick={handleResendValidationEmail}
                disabled={showTimer}
              >
                {t("login.btnResend") as string}
              </button>
            )}
          </Alert>
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="self-stretch flex-col justify-start items-start gap-6 flex"
        >
          <div
            className={`self-stretch bg-white rounded-md border ${
              Object.keys(errors).length > 0
                ? "border-red-500"
                : "border-gray-300"
            } flex-col justify-start items-start flex`}
          >
            <div className="self-stretch px-[13px] py-[9px] bg-white rounded-tl-md rounded-tr-md shadow justify-start items-center inline-flex">
              <input
                type="email"
                placeholder={t("login.label_1").toString()}
                className="text-gray-700 focus:ring-0 p-0 border-none text-base font-normal font-['Figtree'] leading-normal w-full"
                {...register("user_email")}
              />
            </div>
            <div className="self-stretch px-[13px] py-[9px] bg-white rounded-bl-md rounded-br-md shadow border-t border-gray-300 justify-between items-center inline-flex">
              <input
                type={showPassword ? "text" : "password"}
                className="text-gray-700 focus:ring-0 p-0 border-none text-base font-normal font-['Figtree'] leading-normal w-full"
                placeholder={t("login.label_2").toString()}
                {...register("user_password")}
              />

              <button
                className="w-5 h-5 relative"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword ? (
                  <HiEye color="#9CA3AF" size={20} />
                ) : (
                  <HiEyeOff color="#9CA3AF" size={20} />
                )}
              </button>
            </div>
          </div>

          <div className="self-stretch justify-between items-center inline-flex">
            <div className="justify-start items-center gap-2 flex">
              <input
                type="checkbox"
                className="w-4 h-4 relative bg-white rounded border border-gray-300"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
                id="remember-me"
              />
              <label
                className="text-gray-700 text-sm font-normal font-['Figtree'] leading-tight"
                htmlFor="remember-me"
              >
                {t("login.label_3")}
              </label>
            </div>
            <div className="justify-start items-center flex">
              <Link
                to="/forgot-password"
                className="text-right text-sky-600 text-sm font-medium font-['Figtree'] leading-tight"
              >
                {t("login.forgotPassWordBtn")}
              </Link>
            </div>
          </div>

          {Object.keys(errors).length > 0 && (
            <div>
              {Object.keys(errors).map((key) => (
                <p key={key} className="text-red-500 text-sm mb-0">
                  {errors[key]?.message?.toString()}
                </p>
              ))}
            </div>
          )}

          <Button
            type="submit"
            className="w-full bg-sky-500 font-['Figtree']"
            disabled={LoginUserMutation.isLoading}
            isProcessing={LoginUserMutation.isLoading}
            processingSpinner={
              <AiOutlineLoading className="h-6 w-6 animate-spin" />
            }
            size={"sm"}
          >
            {t("login.loginBtn")}
          </Button>
        </form>
      </div>

      {/* Upgrade plan modal */}
      <Modal show={showPlansModal} size="7xl">
        <Modal.Body>
          <h3 className="text-xl font-medium text-gray-900 dark:text-white mb-5">
            {t("login.text_1")}
          </h3>
          <Plans
            user_identification={userId as string}
            entity_identification={setUserEntityId as string}
            isInBilling={false}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;
