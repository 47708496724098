import { useTranslation } from "react-i18next";
import Joi from "joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { toast } from "react-toastify";
import { TextInput, Label, Button } from "flowbite-react";
import BsInputPassword from "../../../public/BsInputPassword";

const schema = Joi.object({
  user_email: Joi.string().trim().required().label("Email"),
  user_password: Joi.string().trim().required().label("Password"),
}).required();

const LoginModal = ({
  showModal,
  serviceData,
  isRealLogin,
  handleLoginViaLink,
  externalPage,
}: {
  showModal: boolean;
  serviceData: { name: string; logoUrl: string };
  isRealLogin?: boolean;
  handleLoginViaLink?: (loginData: any) => void;
  externalPage?: boolean;
}) => {
  const [t] = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(schema),
  });

  const onSubmit = () => {
    if (!isRealLogin) {
      toast.success(t("dashboard.features.loginTemplate.loginModal.text1"));
    } else if (handleLoginViaLink) {
      const data = getValues();
      handleLoginViaLink({ ...data });
    }
  };

  return (
    <div
      className={`w-full ${
        externalPage ? "md:w-[792px]" : ""
      } mx-auto self-center px-6 pt-6 pb-10 bg-white rounded-lg shadow border border-gray-300 flex-col justify-start items-center gap-6 inline-flex`}
    >
      <img
        className="w-16 h-16 relative bg-gray-100 rounded-[32px]"
        src={`${
          serviceData.logoUrl !== ""
            ? serviceData.logoUrl
            : "/images/login-modal-logo.svg"
        }`}
        alt="login modal logo"
      />

      <h2 className="text-center text-gray-700 text-lg font-semibold font-['Figtree'] leading-7">
        {t("dashboard.features.loginTemplate.loginModal.text2")}{" "}
        {`${
          serviceData.name !== ""
            ? serviceData.name
            : t("dashboard.features.loginTemplate.loginModal.text3")
        }`}
      </h2>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col justify-start items-center gap-6"
        autoComplete="off"
      >
        <div className="w-full">
          <Label
            htmlFor="user_email"
            className="text-zinc-500 text-xs font-normal font-['Figtree'] leading-none mb-3"
          >
            {t("dashboard.features.loginTemplate.loginModal.text7")}
          </Label>
          <TextInput
            id="user_email"
            className="w-full"
            type="email"
            placeholder={`${t(
              "dashboard.features.loginTemplate.loginModal.text8"
            )}`}
            {...register("user_email")}
            color={!!errors.user_email ? "failure" : undefined}
            helperText={errors.user_email?.message as string}
            disabled={!showModal || !isRealLogin}
            readOnly={!isRealLogin}
          />
        </div>

        <div className="w-full relative">
          <BsInputPassword
            rhfControls={register("user_password")}
            errorMessage={errors.user_password?.message as string}
            isInvalid={!!errors.user_password}
            label={t(
              "dashboard.features.loginTemplate.loginModal.text5"
            ).toString()}
            placeholder={t(
              "dashboard.features.loginTemplate.loginModal.text5"
            ).toString()}
            rest={{ readOnly: !isRealLogin, disabled: !isRealLogin }}
          />
        </div>

        {isRealLogin ? (
          <Button
            type="submit"
            color="dark"
            className={`w-full rounded-md shadow justify-center items-center inline-flex ${
              !showModal ? "bg-gray-400" : "bg-gray-700 hover:bg-gray-600"
            }`}
          >
            <span className="text-white text-sm font-medium font-['Inter'] leading-tight">
              {t("login.loginBtn")}
            </span>
          </Button>
        ) : (
          <div
            className={`w-full px-[17px] py-[9px] rounded-md shadow justify-center items-center inline-flex ${
              !showModal || !isRealLogin ? "bg-gray-400" : "bg-gray-700"
            } ${handleLoginViaLink ? "cursor-pointer hover:bg-gray-600" : ""}`}
          >
            <span className="text-white text-sm font-medium font-['Inter'] leading-tight">
              {t("login.loginBtn")}
            </span>
          </div>
        )}
      </form>
    </div>
  );
};

export default LoginModal;
